import React from 'react'
import {Layout} from "../components/utilityComponents/layout";
import {Button} from "../components/utilityComponents/button";
import {goToUrl} from "../helpers/goToUrl";

const NotFoundPage = () => {
  return (
    <Layout smallNavbar ><div className="FoF">
      <h1 className="FoF__header">404</h1>
      <p className="FoF__paragraph">Przepraszamy lecz nie odnaleźmy owej strony.<br/>
          Powróć na stronę główną.</p>
      <Button type="primary" label="Strona główna"  size="large" onClick={()=>goToUrl("/")}/>
    </div></Layout>
  );
};

export default NotFoundPage;
